<template>
  <div class="geralas">
    <div
      class="d-flex w-100 flex-column mb-4"
    >
      <div class="d-flex w-100 justify-space-between">
        <cabecalho-pagina
          :titulo="$t('modulos.tipo_instrumento.titulos.tipo_instrumento') + ' - ' + tipoInstrumento.nome"
          :total-registros="tabela.quantidadeItens"
          sem-filtros
          sem-botoes
        />
        <input-select
          v-model="faixaSelecionadaId"
          :label="$t('modulos.tipo_instrumento.faixas')"
          :options="opcoes.faixas"
          class="mb-2"
          em-filtro
        />
      </div>
      <div class="d-flex flex-row justify-space-between">
        <div>
          <botao-padrao
            v-if="podeExcluir"
            class="my-2"
            outlined
            color="danger"
            @click="excluirRegistros"
          >
            <v-icon>$mdiTrashCanOutline</v-icon>
            {{ $t('geral.botoes.excluir') }}
          </botao-padrao>
        </div>
        <div class="d-flex align-center flex-row justify-space-between w-auto">
          <botao-padrao
            class="mr-0"
            @click="abrirNovo"
          >
            {{ $t('modulos.tipo_instrumento.botoes.novo_ponto') }}
          </botao-padrao>
        </div>
      </div>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      paginacao-em-memoria
      sem-paginacao
      ajustar-dropdown-acima
      :pagina-atual="tabela.paginaAtual"
      sort-field="valor"
      :sort-order="1"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      :quantidade-paginas="tabela.quantidadePaginas"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item @click="abrirEditar(slotProps.data)">
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
    <template>
      <v-divider class="my-5" />
      <div class="d-flex align-center justify-end">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          @click="salvar"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </div>
    </template>
    <PontosForm
      ref="modal-pontos"
      @novoPonto="novoPonto"
    />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import PontosForm from './components/Modal/PontosForm'
import TipoInstrumentoService from '@common/services/cadastros/TipoInstrumentoService.js'
import { TipoInstrumentoModel } from '@common/models/cadastros/TipoInstrumentoModel'
import { DropdownFaixas } from '@common/models/geral/DropdownFaixas';
import helpers from '@common/utils/helpers.js';

export default {
  components: {
    CabecalhoPagina, PontosForm
  },
  data() {
    return {
      tipoInstrumento: {},
      faixaSelecionadaId: null,
      faixaSelecionada: {},
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'valor',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.valor'),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'descricao',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.descricao'),
            sortable: true,
          },
          {
            value: 'tipoPonto',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.tipo_ponto'),
            sortable: true,
            formatter: (v) => helpers.TipoPontoEnum.find(el => el.value == v)?.text
          },
          {
            value: 'origemPonto',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.origem_ponto'),
            sortable: true,
            formatter: (v) => helpers.OrigemPontoEnum.find(el => el.value == v)?.text
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      opcoes: {
        faixas: []
      }
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
  },
  watch: {
    faixaSelecionadaId(id) {
      this.buscarFaixaSelecionada(id);
    },
    faixaSelecionada: {
      handler(){
        this.tabela.dados = this.faixaSelecionada.tipoInstrumentoFaixaPonto
        this.tabela.quantidadeItens = this.faixaSelecionada.tipoInstrumentoFaixaPonto.length
        this.tabela.quantidadePaginas = Math.ceil(this.tabela.quantidadeItens / this.tabela.porPagina) || 0
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.tipo_instrumento.titulos.listagem')
    );
    this.buscar();
  },
  methods: {
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoInstrumentoService.buscar(this.$route.params.id)
        .then((res) => {
          this.tipoInstrumento = new TipoInstrumentoModel(res.data);
          this.opcoes.faixas = new DropdownFaixas(res.data.faixa)
          this.faixaSelecionadaId = this.opcoes.faixas[0]?.value;
        })
        .catch(() => {
          this.mostrarIdInvalido();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.faixaSelecionada.tipoInstrumentoFaixaPonto.splice(this.faixaSelecionada.tipoInstrumentoFaixaPonto.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
      });
    },
    abrirNovo() {
      if(!this.faixaSelecionadaId) {
        this.toastAlerta(this.$t('modulos.tipo_instrumento.erros.selecione_faixa'))
        return
      }
      this.$refs['modal-pontos'].abrirModal(
        this.tipoInstrumento.nome,
        this.faixaSelecionada,
        null,
        this.tipoInstrumento.flagPontosDuplicados
      );
    },
    abrirEditar(pontoCalibracao) {
      this.$refs['modal-pontos'].abrirModal(
        this.tipoInstrumento.nome,
        this.faixaSelecionada,
        pontoCalibracao,
        this.tipoInstrumento.flagPontosDuplicados
      );
    },
    buscarFaixaSelecionada(){
      this.faixaSelecionada = this.tipoInstrumento.faixa.find(el => el.id === this.faixaSelecionadaId)
    },
    novoPonto(ponto){
      this.faixaSelecionada.tipoInstrumentoFaixaPonto.push(ponto)
    },
    cancelar(){
      this.$router.push({ name: 'tipo-instrumento' });
    },
    salvar(){
      TipoInstrumentoService.salvar(this.tipoInstrumento.request)
        .then(() => {
            this.toastSucesso(
              this.$t(`modulos.tipo_instrumento.edicao_sucesso`)
            );
          this.$router.push({ name: 'tipo-instrumento' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
